body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--darkGrey);
  -webkit-font-smoothing: antialiased;
  color: white;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar{
  display: none;
}

:root {
  --lightgray: #d9d9d9;
  --gray: #9c9c9c;
  --orange: #f48915;
  --darkGrey: #464d53;
  --caloryCard: #656565;
  --planCard: linear-gradient(210.41deg,#fa5042 1.14%,#ffa739 100.75%);
  --appColor: #3c3f45;
}
  .primarytext {
    font-size: 3rem;
    font-family: Arial, Helvetica, sans-serif;
      gap:1.3rem;
    padding: 10px 0px;
  }
  .secondarytext {
    font-size: 19px;
    color: white;
  }
  .thirdtext{
    font-size: 14px;
    color: whitesmoke;
  }
  .pad{
    padding: 2rem;
    padding-left: 0;
  }
  .flex{
     display: flex;
     gap:1rem;
     align-items: baseline;
   
  }
  .flexcenter{
    display: flex;
    align-items: center;
  }
  .main{
    padding: 2rem;

  }
  .flexcolumn{
    display: flex;
    flex-direction: column;

  }

  .flexEnd {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .stroke-text{
    color:transparent;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-text-stroke: 1px;
    -webkit-text-stroke-color: white;
  }
  .pro-head{
    display: flex;
    font-size: 3.3rem;
    text-align: center;
    justify-content: center;
    align-items: baseline;
    gap: 3.5rem;
    padding:2rem;
}
.orange{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: var(--orange);
  font-size: 1.2rem;
}
.white-grd{
  position:absolute;
  z-index: -22;
  width: 20rem;
  height: 20rem;
  background: var(--gray);
  filter:blur(130px);
  border-radius:100px;
 
}
.orange-grd{
  position:absolute;
  z-index: -22;
  width: 20rem;
  height: 20rem;
  background: var(--planCard);
  filter:blur(180px);
  border-radius:100px;
 
}
@media(max-width:790px){
    .pro-head{
      gap: 1rem;
      font-size: 2rem;
    }
}

@media(max-width:540px){
    .pro-head{
      flex-direction: column;
     align-items: center;
    }
    .primarytext{
      font-size: 2rem;
    }
}