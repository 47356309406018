.l-img{
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    padding: 2rem ;
    flex:1;
    max-width: 42rem;
}
.left>img{
    height: 32rem;
}
.right>img{
    width: 25rem;
}
.b-img{
    display: flex;
    gap: 1rem;
}
.b-img>img{
    width: 12rem;
    padding-top: 2rem;
}
.r-menu{
    display: flex;
    padding: 1.3rem;
}
.r-sides{
    background: none;
    padding: 2rem;
    flex:2;
}

.bull{
    display: flex;
    flex-direction: column;
    gap:1rem
}
.bulletins{
    font-size:1.06rem;
    display: flex;
    align-items: center;
    gap:1rem;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.bulletins>img{
    width: 2.2rem;
}

.sponsor>img{
    width: 2.3rem;
    padding: 10px 5px;
}

@media(max-width:800px){
      .r-menu{
        flex-direction: column;
       
      }
      .r-sides{
        padding-left: 0rem;
        left: 2rem;
      }
}

@media(max-width:680px){
    .l-img{
        padding: 1rem;
    }
    .left>img{
        height: 22rem;
    }
    .right>img{
        width: 15.5rem;
    }
    .b-img>img{
        width: 7.4rem;
        padding-top: 1rem;
    }
}