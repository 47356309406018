

.prog-list{
    display: flex;
    justify-content: center;
    gap:1.5rem;
    padding: 10px;
}
.list{
    background: rgb(151, 149, 149);
    padding:25px;
    display: flex;
    flex-direction: column;
    gap:1rem
}
.p-icon>:nth-child(1){
    height: 2rem;
    width: 2rem;
    /* fill since content is image we cant hv color */
    fill: white;
}
.list:hover{
    background: var(--planCard);
    cursor: pointer;
}
.p-content{
    width:auto;
    height: max-content;
}
.p-head{
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
}
.join{
    gap:1rem;
    display: flex;
   align-items: center;
   
  }
  .join img{
    width:1.5rem;
    height: 1.2rem;
   
  }

  @media(max-width:680px){
   .prog-list{
      flex-direction: column;
   }
   
}