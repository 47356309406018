.j-wrapper{
    padding:0rem 2rem;
    display: flex;
    justify-content: center;
}
.joins{
    display: flex;
    flex-direction: column;
    padding: 2.4rem;
    gap:1.4rem;
   align-items: center;
   text-align: center;
   background: var(--planCard);
   border-radius: 10px;
  
}

.prim{
    font-size: 2.4rem;
    font-family: Arial, Helvetica, sans-serif;
}
.sec{
    font-size: 1.1rem;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color:var(--grey);
}
.button{
    padding:8px 15px;
    background: white;
    border-radius: 4px;
}
.button>a{
    text-decoration: none;
    color:orange;
}
.footer{
    display: flex;
    gap:3rem;
    padding: 8rem 1rem;

    justify-content: center;
}
.footer>img{
    width: 4rem;
}
.footgrad{
    display: flex;
    flex-direction: column;
    align-items: center;
}