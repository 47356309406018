.header{
    justify-content: space-between;
   
}
.title{
    font-size: 1.2rem;
    color: white;
   
}
.heading{
   padding-top:1rem ;
   display: flex;
}
.heading a{
    padding: 1rem;
    font-size: 1.1rem;
    text-decoration: none;
    color: whitesmoke;
}    
.dec{
    color: white;
    background: var(--appColor);
    padding: 17px 22px;
    border-radius: 22px;
    min-width: 16rem;
    position: relative;
    display: flex;
    align-items: center;
    width: fit-content;
    z-index: -2;
    overflow: hidden;
    transition: all 300ms ease-in-out

   
}

.dec .orangeslider{
  
    width: 5rem;
     height: 2.9rem;
    border-radius: 1.2rem;
    position: absolute;
    background: var(--orange);
    z-index: -1;
    right: 2rem;
}  
.dec span{
    transition: all;
    z-index: 2;
}
.counter{
    gap:1.2rem;
}
.meter>:nth-child(2){
    color: grey;
}
.secondarytext span{
    color: white;
}
.get{
    padding: 12px 0;
    width: 7.5rem;
    color: white;
    background: var(--orange);
    font-size: 15px;
}
.learn{
    padding: 12px 0;
    width: 7.5rem;
    color: white;
    background: none;
    font-size: 15px;
    border-color: var(--orange) ;
}
.l-side{
    padding: 1rem;
    flex:3;

}
.orangeback{
    width: 26rem;
    height: 45rem;
    position: absolute;
    top:-3rem;
    background: var(--planCard);
    z-index: -4;
}
.r-side{
    flex:1;
    background-color: var(--orange);
    position: relative;
    background-color: var(--planCard);
    z-index: 2;
    max-width: 340px;
 
}
.hero{
    padding: 0rem 1rem;
    padding-right: 0;
    display: flex;
   justify-content: space-between;
}
.hero-text{
    font-size: 4.5rem;
}
.heart{
    background: var(--appColor);
    height: fit-content;
    width: fit-content;
    gap:4px;
    padding: 11px;
    position: relative;
    z-index: 1;
    left:59%;
    top:-5rem;
  
}
.flexEnd {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    top:-5rem;
  }
.heart img{
    width: 2rem; 

}
.calory{
    display: flex;
    align-items: last baseline;
    background: #656565;;
    width: fit-content;
    height:fit-content;
    max-width: 120px;
    position: relative;
    padding: 1rem;
    gap:1.4rem;
    z-index: 1;
   left:-120%;
   top:35rem;
  right: 40%;
    
}
.calory img{
    width:2.8rem;
   height:5rem;
   
}
.button{
    width: fit-content;
    padding: 5px 4px;
    font-style: italic;
    font-size: 1rem;
   border-radius: 4px;
   border-color: var(--orange);
   margin-right: 2rem;
}

.hero-img{
    position:relative;
}
.hero-img :nth-child(1){
    position:absolute;
    z-index: 0;
    width:15rem;
    top:-24%;
    left: -24%;
}
.hero-img :nth-child(2){
    position: relative;
    z-index: 1;
    width:22rem;
    left:-25%;
    top: -6rem;;
}
.secondarytex{
    color: white;
    font-family: Arial, Helvetica, sans-serif;
        font-size: 1.7rem;
}

@media(max-width:790px){
    .calory{
     padding-left: 5rem;
    }
    .calory img{
        width:1.8rem;
        height:3.5rem;
    }
    .hero{
        flex-direction: column;
    }
    .r-side{
        position: relative;
        left:12rem
    }
    .orangeback{
        background: none;
    }
    .heading{
        flex-direction: column;
        position: fixed;
        right: 2rem;
        z-index: 100;
        background: var(--appColor);
        padding: 10px;
       
   }
   .hrt{
    max-width: 9rem;
    position: relative;
    left: 8rem;
}


   .calory{
    left:-11rem;
   top:40rem
}
}
@media(max-width:530px){

    .hero-img :nth-child(2){
        width: 15rem;
    }
    .hero-img :nth-child(1){
        width: 12rem;
       
    }
    .hrt{
        position: relative;
        right: 1rem;
    }
    .flexEnd{
        top:-5.5rem;
    }
    .calory{
       position: absolute;
      left: -2rem;
        top:5rem;
    }
    .hero-text{
        font-size: 3.5rem;
    }
    
       
}