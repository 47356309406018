.t-container{
    overflow: hidden;
    position: relative;
    padding: 6rem 2rem;
}

.testimony{
    display: flex;
    justify-content: space-between;
}
.truth{
    max-width: 36rem;
    font-size: 1.1rem;
}
.o-text{
    color: orange;
    
}
.m-img{
    position:relative;
   
}

.b1{
    position: absolute;
    z-index:1;
    width:15rem;
    height:18rem;
    border: 2px solid orange;
    left:-8rem;
    top:0rem   
}
.b2{
    position: absolute;
    z-index:2;
    width:15rem;
    height:18rem;
     background: var(--planCard);
    left:-2.2rem;
    top:4.7rem   
}
.m-img>:nth-child(1){
    width:15rem;
    height:19rem;
   position:relative;
   z-index: 9;
   left: -5rem;
   top:1.8rem;
}

.r-buttons{
    position: relative;
    top:-6rem;
    right:-52rem;
    z-index: 10;
}
.r-buttons button{
   
    font-size: 2rem;
    padding: 0.2rem 1rem;
    margin: 0.7rem;
    border: none;
    border-radius: 8px;
    background: var(--planCard);
    cursor: pointer;
}

@media(max-width:800px){
    .testimony{
        flex-direction: column;
    }
    .r-buttons{
        right: -12rem;
        top:-26.3rem
    }
    .r-buttons button{
        font-size: 1.5rem;
        padding: 0.2rem 0.7rem;
    }
}
