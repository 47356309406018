.plancard{
    display:flex;
    gap:3rem;
    justify-content: center;
}
.lists{
    background: var(--caloryCard);
    display: flex;
    flex-direction: column;
    position:relative;
    gap:1rem;
    padding: 30px 30px 35px 30px;
    width: 14rem;
    min-width: 14rem;
    cursor: pointer;
}
.lists button{
     padding: 15px 20px;
    color: var(grey);
    border-color: none;
    
}
.features{
    height:7rem;
}
.feature>img{
    width:1rem;
    height:1rem
}
.plan>:nth-child(1){
    width:2rem;
    height: 2rem;
    fill:orange
}
.plancard>:nth-child(2){
    background: var(--planCard);
    transform: scale(1.1);
}
.plancard>:nth-child(2)>.plan>:nth-child(1){
    fill:white;
}
.plancard>:nth-child(2)>button{
    color: orange;
    border-color: orange;
}
.grad{
    display: flex;
    justify-content: space-between;
   
    gap:2rem;
}
.grad1{
    position: relative;
    flex: 2;
}
.grad2{
    position: relative;
   flex:1;
}

@media(max-width:800px){
    .plancard{
        flex-direction: column;
        align-items: center;
        gap:4rem;
    }
}